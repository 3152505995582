<template>
  <div>
   <v-bottom-navigation 
   color="primary"
   fixed
   grow
   style="padding-bottom: env(safe-area-inset-bottom);"
 >
   <v-btn rounded small icon v-for="item in items" :key=item.text :to="item.to">
     <span style="size:8px">{{ item.text }}</span>
     <v-icon small>{{ item.icon }}</v-icon>
   </v-btn>

    </v-bottom-navigation>

    </div>
</template>

<script>
export default {
  data: () => ({
    // name: localStorage.getItem('name'),
       domain: '',
       items: []
   
  }),
  mounted () {
    this.domain = this.$route.params.domain
    this.items = [
        //  {
        //   to: '/sites/'+this.domain+'/backups',
        //   icon: 'mdi-file-multiple',
        //   text: 'Backups'
        // },
      // {
      //   to: '/sites/'+this.domain+'/redirects',
      //   icon: 'mdi-cached',
      //   text: 'Redirects'
      // },
    
      {
        to: '/sites/'+this.domain+'/analytics',
        icon: 'mdi-google-analytics',
        text: 'Analytics'
      },
         {
        to: '/sites/'+this.domain,
        icon: 'mdi-book-outline',
        text: 'Overview'
      },
      {
        to: '/sites/'+this.domain+'/tools',
        icon: 'mdi-wrench-outline',
        text: 'Tools'
      }]
     
  }

}
</script>
