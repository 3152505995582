<template>
  <v-main
    class="fill-height ml-4 mr-4 mt-4 mb-4"
    fluid
  >



      <BottomNavBar/>


</v-main>
</template>

<script>
import BottomNavBar from "@/components/BottomNavBar";

export default {
   components: {
    BottomNavBar
  },
  data: () => ({
       domain: '',
   
  }),
  created () {
    this.domain = this.$route.params.domain 
  }

}
</script>
